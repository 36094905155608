












import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import CurrencyInput from '@/components/common/CurrencyInput.vue';
import { CurrencyInputOptions, CurrencyDisplay } from 'vue-currency-input';
@Component({
  components: { CurrencyInput },
})
export default class SplitInput extends Vue {
  @Prop({ required: true })
  headText!: string;

  @Prop()
  currentValue!: string;

  currentInput: number | null = null;

  get options() {
    const options: CurrencyInputOptions = {
      autoDecimalDigits: true,
      precision: 2,
      locale: undefined,
      currency: 'EUR',
      currencyDisplay: CurrencyDisplay.hidden,
      valueRange: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    };
    return options;
  }

  @Watch(`currentValue`, { immediate: true })
  updateCurrentValue() {
    this.currentInput = this.currentValue.length > 0 ? Number(this.currentValue) : null;
  }

  @Emit(`update`)
  emitValue(value: number) {
    this.currentInput = value;
    return this.currentInput ? this.currentInput.toString() : '0.00';
  }
}
