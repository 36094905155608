<template>
  <input
    :class="[$style.currencyInputContainer]"
    ref="inputRef"
    type="text"
    :value="formattedValue"
  />
</template>

<script>
// Template from https://dm4t2.github.io/vue-currency-input/guide.html#creating-a-custom-component
// https://vue-currency-input-next.netlify.app/guide.html#external-props-changes
import { watch } from '@vue/composition-api';
import { useCurrencyInput } from 'vue-currency-input';
export default {
  name: 'CurrencyInput',
  props: {
    value: Number, // Vue 2: value
    options: Object,
  },
  setup(props) {
    const { inputRef, formattedValue, setOptions, setValue } = useCurrencyInput(props.options);

    watch(
      () => props.value,
      (value) => {
        setValue(value);
      }
    );

    watch(
      () => props.options,
      (options) => {
        setOptions(options);
      }
    );

    return { inputRef, formattedValue };
  },
};
</script>

<style lang="scss" module>
.currencyInputContainer {
  min-width: 150px;
  max-height: 30px;
  background-color: white;
  outline: none;
  border: none;
  font-size: 16px;
  box-shadow: none;
}
</style>
